<template>
  <div id="app">
    <el-container style="height: 98vh;">
      <el-aside>
        <LeftMenu/>
      </el-aside>
      <el-container>
        <el-header>
          <TopHeader/>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import LeftMenu from "@/views/LeftMenu.vue";
import TopHeader from "@/views/TopHeader.vue";

export default {
  components: {LeftMenu, TopHeader}
};

</script>
<style scoped>
.el-aside {
  color: #333;
  width: auto !important;
}

.el-header {
  text-align: center;
  background-color: lightseagreen;
  color: white;
  line-height: 60px;
  font-size: 24px;
}
</style>
