<template>
  <div>
    {{ menuTitle }}
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      menuTitle: "菜单标题",
    };
  },
  computed: {
    ...mapState(["leftMenuList"]),
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.menuTitle = val.meta.title;
        }
      },
    },
  },
};
</script>

<style scoped>
</style>
