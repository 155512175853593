import store from '../store'

const routes = []

function pushRoutes(obj) {
    if (obj.subMenu) {
        obj.subMenu.forEach(sub => {
            pushRoutes(sub)
        })
    } else {
        routes.push({
            name: obj.name,
            path: obj.path,
            component: () =>
                import('@/' + obj.componentPath),
            meta: {
                title: obj.label
            }
        });
    }
}

store.state.leftMenus.forEach(item => {
    pushRoutes(item)
});

export default routes;
