export const mixin = {
    methods: {
        getPrettyDate(date) {
            let now = new Date();
            let number;
            let oneDay = 24 * 60 * 60 * 1000;
            if (typeof (date) == "string") {
                number = Math.floor((now - new Date(date)) / oneDay);
            } else {
                number = Math.floor((now - date) / oneDay);
            }

            if (number === 0) {
                return "今天"
            } else if (number <= 7) {
                return number + "天前"
            } else {
                return date
            }
        }
    }
}