export default [
    {
        name: "eventDetail",
        label: "活动详情",
        path: "/activity",
        iconClass: "el-icon-info",
        componentPath: "views/activity/index.vue",
        subMenu: [
            {
                name: "activity-TDZC",
                label: "天地战场",
                path: "/activity/tian-di-zhan-chang",
                componentPath: "views/activity/tian-di-zhan-chang/index.vue"
            },
            {
                name: "activity-BFYS",
                label: "本服要塞",
                path: "/activity/ben-fu-yao-sai",
                componentPath: "views/activity/ben-fu-yao-sai/index.vue",
            },
            {
                name: "activity-KFYS",
                label: "跨服要塞",
                path: "/activity/kua-fu-yao-sai",
                componentPath: "views/activity/kua-fu-yao-sai/index.vue",
            }
        ],
    },
    {
        name: "resource",
        label: "资源分配",
        path: "/resource",
        iconClass: "el-icon-s-goods",
        componentPath: "views/resource/index.vue",
        subMenu: [
            {
                name: "resource-xiao",
                label: "晓组织奖励",
                path: "/resource/xiao",
                componentPath: "views/resource/xiao/index.vue",
            },
            {
                name: "resource-BFB",
                label: "本服要塞包",
                path: "/resource/ben-fu-bao",
                componentPath: "views/resource/ben-fu-bao/index.vue",
            },
            {
                name: "resource-KFB",
                label: "跨服要塞包",
                path: "/resource/kua-fu-bao",
                componentPath: "views/resource/kua-fu-bao/index.vue",
            }
        ],
    },
    {
        name: "organization",
        label: "组织",
        path: "/organization",
        iconClass: "el-icon-user-solid",
        componentPath: "views/organization/index.vue"
    },
    {
        name: "mirai",
        label: "群机器人",
        path: "/mirai",
        iconClass: "el-icon-chat-dot-round",
        componentPath: "views/mirai/index.vue"
    }
];
