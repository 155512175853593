const huoyingServerSrc = process.env.NODE_ENV === 'production' ? "https://www.ianunei.fun/huoying" : "http://124.70.202.252:8101"
const huoyingServer = {
    queryPlayer: huoyingServerSrc + "/player/query",
    queryTianDiZhanChangRecord: huoyingServerSrc + "/tian-di-zhan-chang-record/query",
    queryXiaoRewardRecord: huoyingServerSrc + "/xiao-reward/query",
    queryYaoSaiBaoRecord: huoyingServerSrc + "/yao-sai-bao-record/query",
    queryYaoSaiRecord: huoyingServerSrc + "/yao-sai-record/query",
}

const miraiServerSrc = process.env.NODE_ENV === 'production' ? "https://www.ianunei.fun/bot" : "http://124.70.202.252:8201"
const miraiServer = {
    pixiv: miraiServerSrc + "/pixiv",
    miraiInfo: miraiServerSrc + "/mirai/info"
}

export default {
    huoyingServer, miraiServer
} 