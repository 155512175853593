<template>
  <div style="height: 98vh !important;" @click="blur()">
    <el-menu :default-active="$route.path" router :collapse="Boolean(isCollapse)" :collapse-transition="false">
      <el-button @click="isCollapse = !isCollapse" style="margin-bottom: 10px;">
        {{ isCollapse ? ">>>" : "<<<" }}
      </el-button>
      <template v-for="item in leftMenus">
        <el-submenu :key="item.name" v-if="item.subMenu" :index="item.path">
          <template slot="title">
            <i :class="item.iconClass"></i>
            <span slot="title">{{ item.label }}</span>
          </template>
          <el-menu-item v-for="menuItem in item.subMenu" :index="menuItem.path" :key="menuItem.name">
            {{ menuItem.label }}
          </el-menu-item>
        </el-submenu>
        <el-menu-item :key="item.name" v-else :index="item.path">
          <i :class="item.iconClass"></i>
          <span slot="title">{{ item.label }}&emsp;&emsp;</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      isCollapse: false
    }
  },
  methods: {
    blur() { // 移除焦点
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }
  },
  computed: {
    ...mapState(["leftMenus"]),
  },
};
</script>
<style scoped>
.el-menu {
  height: 98vh;
  width: auto;
  user-select: none;
}
</style>