import Vue from "vue"
import Vuex from "vuex"

import leftMenus from './leftMenus'
import servers from './servers'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        leftMenus,
        servers
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {},
})
