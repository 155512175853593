//该文件专门用于创建整个应用的路由器

import VueRouter from "vue-router";
import routes from './routes'

//创建一个路由器
const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
});

export default router;
