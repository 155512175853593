import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import router from './router';
import store from "./store"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import JsonExcel from 'vue-json-excel';
import {mixin} from "@/mixin"

Vue.component('DownloadExcel', JsonExcel)
Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ElementUI);
Vue.mixin(mixin)

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
